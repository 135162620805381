@import "../../global.scss";

.works {
    background-color: $blackish;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    h1 {
        font-size: 50px;
        margin-top: 20px;
        color: white;
        position: absolute;
        top: 0;

        @include mobile {
            opacity: 0;
        }
    }

    .arrow {
        height: 50px;
        position: absolute;
        cursor: pointer;

        @include mobile {
            display: none;
        }

        &.left {
            left: 100px;
            transform: rotateY(180deg);
        }

        &.right {
            right: 100px;
        }
    }

    .slider {
        height: 500px;
        display: flex;
        position: absolute;
        left: 0;
        transition: all 1s ease-out;

        @include mobile {
            height: 100vh;
            flex-direction: column;
            justify-content: center;
        }

        .container {
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            @include mobile {
                height: 100vh;
            }

            .item {
                width: 1000px;
                height: 100%;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: relative;

                @include mobile {
                    width: 80%;
                    margin: 15px 0;
                }

                .header {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    color: white;

                    @include mobile {
                        font-size: 0.4;
                    }

                    h2 {
                        font-size: 40px;
                
                        @include mobile {
                            font-size: 20px;
                        }
                    }

                    h3 {
                        @include mobile {
                            font-size: 18px;
                        }
                    }

                    h4 {
                        @include mobile {
                            font-size: 14px;
                        }
                    }

                    .imgContainer {
                        width: 150px;
                        height: 150px;
                        background-color: white;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @include mobile {
                            width: 0px;
                            height: 0px;
                        }

                        img {
                            width: 75%;
                        }
                    }
                }

                .content {
                    flex: 0.4;
                    width: fit-content;
                    text-align: center;
                    color: white;
                    margin-top: 10px;

                    @include mobile {
                        flex: 2;
                    }

                    p {
                        font-size: 18px;

                        @include mobile {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}