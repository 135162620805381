@import "../../global.scss";

.portfolio {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    h1 {
        font-size: 50px;
        margin-top: 20px;
        overflow: visible;

        @include mobile {
            font-size: 20px;
            margin-top: 5px;
        }
    }

    ul {
        margin: 10px;
        padding: 0;
        list-style: none;
        display: flex;
        overflow: visible;

        @include mobile {
            margin: 10px 0;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    .container {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        @include mobile {
            width: 100%;
        }

        .itemContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .item {
                width: 300px;
                height: 250px;
                border-radius: 20px;
                border: 1px solid rgb(240, 239, 239);
                margin: 10px 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: white;
                transition: all 0.5s ease;
                cursor: pointer;
                box-shadow: 0px 0px 5px 0px black;
    
                @include mobile {
                    width: 130px;
                    height: 100px;
                }

                &:hover {
                    transform: scale(1.05);
                }

                a {
                    width: 100%;
                    height: 100%;
                    text-decoration: none;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            h3 {
                color: $blackish;
                font-size: 20px;
            }
        }
    }
}