@import "../../global.scss";

.menu {
    width: 300px;
    height: 75vh;
    background-color: $blackish;
    position: fixed;
    border-bottom-left-radius: 20px;
    top: -75vh;
    right: 0;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;

    &.active {
        top: 0;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 30px;
        font-weight: 300;
        color: $white;
        width: 60%;

        li {
            margin-bottom: 25px;
            a {
                font-size: inherit;
                color: inherit;
                text-decoration: none;
            }

            &:hover {
                font-weight: 500;
            }
        }
    }
}