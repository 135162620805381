@import "../../global.scss";

.intro {
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @include mobile {
        flex-direction: column;
    }

    .left {
        flex: 0.35;
        height: 100%;
        min-height: 300px;
        min-width: 325px;
        overflow: hidden;
        background-color: $blackish;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        position: relative;

        @include mobile {
            width: 100%;
        }

        h1 {
            font-size: 53px;
            margin: 10px 0;
            overflow: hidden;

            @include mobile {
                font-size: 40px;
            }
        }

        h2 {
            font-size: 35px;
            overflow: hidden;
        }

        h3 {
            font-size: 30px;
            overflow: hidden;

            @include mobile {
                font-size: 20px;
            }
        }

        .icon {
            font-size: 45px;
            color: white;
            position: absolute;
            bottom: 25px;
            animation: arrowDown 1.5s infinite;
            cursor: pointer;

            @include mobile {
                opacity: 0;
            }
        }

        @keyframes arrowDown {
            50% {
                bottom: 10px;
            }

            100% {
                bottom: 25px;
            }
        }
    }

    .right {
        flex: 0.65;
        height: 100%;
        color: $blackish;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        text-align: center;

        h2 {
            font-size: 35px;
            padding-top: 35px;
            overflow: visible;

            @include mobile {
                font-size: 30px;
                padding-top: 0px;
            }
        }

        .bottom {
            flex: 0.95;
            width: 100%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                display: block;
                width: auto;
                height: 90%;
                box-shadow: 0px 0px 15px -4px black;
                transition: all 1s ease;
                cursor: pointer;
                overflow: hidden;
                border-radius: 10px;
                z-index: 999;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }
}