$blackish: #202020;
$darkGrey: #2F2F2F;
$medGrey: #707070;
$yellow: #FFDF6C;
$white: #FFFFFF;

$width: 768px;

@mixin mobile {
    @media (max-width: #{$width}) {
        @content
    }
}