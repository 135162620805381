@import "../../global.scss";

.portfolioList {
    font-size: 16px;
    margin-right: 50px;
    padding: 7px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.5s ease;
    overflow: hidden;

    @include mobile {
        margin-right: 20px;
    }

    &:hover {
        transform:rotate(5deg);
    }

    &.active {
        background-color: $blackish;
        color: white;

        &:hover {
            transform:rotate(0deg);
        }
    }
}